<template xmlns:DxLegend="http://www.w3.org/1999/html">
  <b-card class="bg-card">

    <div class="work_images">
      <div v-if="swiperLoading" class="spinner-border m-auto" role="status">
        <span class="sr-only">Loading... </span>
      </div>
      <div v-else-if="error.length !== 0" class="m-auto">
        {{ error }}
      </div>

      <div v-else-if="error.length === 0" style="width: 100%">
        <swiper
            class="swiper-responsive-breakpoints"
            :options="swiperOptions"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        >
          <swiper-slide v-for="(data, index) in swiperData" :key="index">
            <b-img
                v-if="data.image != null"
                :src="'data:image/png;base64,' + blobToBase64(data.image.data)"
                fluid
            />
          </swiper-slide>

          <div slot="pagination" class="swiper-pagination"/>
        </swiper>
      </div>
    </div>

    <div class="fama-table">
      <div v-if="dataLoading" class="spinner-border m-auto" role="status">
        <span class="sr-only">Loading... </span>
      </div>
      <div v-else-if="error.length !== 0" class="m-auto">
        {{ error }}
      </div>
      <div v-else-if="error.length === 0" style="width: 100%">

        <DxDataGrid
            id="work-table"
            width="100%"
            :show-borders="true"
            :data-source="data"
            :allow-column-reordering="true"
            :allow-column-resizing="true"
            column-resizing-mode="widget"
            :column-auto-width="true"
            :column-auto-height="true"
            :show-column-lines="true"
            :show-row-lines="true"
            :row-alternation-enabled="true"
            :hover-state-enabled="true"
            :word-wrap-enabled="true"
            key-expr="id"
        >
          <DxColumn
              data-field="work_problems"
              name=""
              caption=""
              cell-template="workProblemTemplate"
              :allow-editing="false"
              :allow-exporting="false"
              :visible="workProblemVisible"
          />
          <template #workProblemTemplate="{ data: problemData }">
            <p v-if="problemData.value.length === 0"></p>
            <img
                v-else
                src="@/assets/images/icons/shovel-yellow.png"
                class="famayellow"
                width="20px"
                height="20px"
                style="float: left"
            />
          </template>
          <DxColumn
              data-field="work_date"
              :name="$t('table.date')"
              :caption="$t('table.date')"
              data-type="date"
              format="dd.MM.yyyy"
              :filter-value="new Date()"
          />
          <DxColumn
              data-field="work_img_1.image.data"
              cell-template="grid-cell-template-pic"
              :width="90"
              :allow-editing="false"
          />
          <template #grid-cell-template-pic="{ data }">
            <img
                :src="
                'data:image/png;base64,' + blobToBase64(data.value)
              "
            />
          </template>
          <DxColumn
              data-field="work_img_2.image.data"
              :width="90"
              :allow-editing="false"
              cell-template="grid-cell-template-pic2"
          />
          <template #grid-cell-template-pic2="{ data }">
            <img
                :src="
                'data:image/png;base64,' + blobToBase64(data.value)
              "
            />
          </template>
          <DxColumn
              data-field="work_img_3.image.data"
              :width="90"
              :allow-editing="false"
              cell-template="grid-cell-template-pic3"
          />
          <template #grid-cell-template-pic3="{ data }">
            <img
                :src="
                'data:image/png;base64,' + blobToBase64(data.value)
              "
            />
          </template>
          <DxColumn
              data-field="shift"
              :name="$t('table.shift')"
              :caption="$t('table.shift')"
          />
          <DxColumn
              data-field="companyName"
              :name="$t('table.company')"
              :caption="$t('table.company')"
          >
          </DxColumn>
          <DxColumn
              data-field="user"
              :name="$t('table.user')"
              :caption="$t('table.user')"
              alignment="left"
              :allow-editing="false"
              cell-template="userTemplate"
          />
          <template #userTemplate="{ data: userData }">
            {{ userData.value.firstname }}
            {{ userData.value.lastname }}
          </template>
          <DxColumn
              data-field="responsible"
              :name="$t('table.responsible')"
              :caption="$t('table.responsible')"
              :visible="false"
              :allow-editing="false"
          />
          <DxColumn
              data-field="activity.wb.code"
              name="WBS #"
              caption="WBS #"
              :allow-editing="false"
              :visible="false"
          />
          <DxColumn
              :set-cell-value="setStateValue"
              data-field="wbs_id"
              :caption="$t('table.wbs_name')"
              :name="$t('table.wbs_name')"
          >
            <DxLookup
                :data-source="dataPopUpWbsName"
                value-expr="id"
                display-expr="mobile_name"
            />
          </DxColumn>
          <DxColumn
              data-field="activity.code"
              :name="$t('table.activity#')"
              :caption="$t('table.activity#')"
              :visible="false"
              :allow-editing="false"
          />
          <DxColumn
              :set-cell-value="setActivityCode"
              data-field="activity_id"
              :name="$t('table.activity')"
              :caption="$t('table.activity')"
          >
            <DxLookup
                :data-source="getFilteredActivity"
                value-expr="id"
                display-expr="name"
            />
          </DxColumn>
          <DxColumn
              data-field="quantity"
              :name="$t('table.quantity')"
              :caption="$t('table.quantity')"
              format="#0.000"
              alignment="right"
          />
          <DxColumn
              data-field="activity.unit_id"
              :name="$t('table.symbol')"
              :caption="$t('table.symbol')"
              :allow-editing="false"
          >
            <DxLookup
                :data-source="dataUnit"
                unit
                value-expr="id"
                display-expr="symbol"
            />
          </DxColumn>
          <DxColumn
              data-field="not_exists_activity"
              :name="$t('table.not_exist_activity')"
              :caption="$t('table.not_exist_activity')"
          />
          <DxColumn
              data-field="not_exists_activity_unit_id"
              name="UM-NeA"
              caption="UM-NeA"
          >
            <DxLookup
                :data-source="dataPopUpUnit"
                value-expr="id"
                display-expr="symbol"
            />
          </DxColumn>
          <DxColumn :visible="true" data-field="notes"/>

          <DxColumn
              data-field="machine_count"
              :name="$t('table.t_mach')"
              :caption="$t('table.t_mach')"
          />

          <DxColumn
              data-field="personel_count"
              :name="$t('table.p_mach')"
              :caption="$t('table.p_mach')"
          />

          <DxSummary>
            <DxGroupItem
                column="activity.wb.languages[0].wbs_translation.name"
                summary-type="count"
            />
            <DxGroupItem
                column="quantity"
                summary-type="sum"
                :align-by-column="true"
                :show-in-group-footer="true"
            />
          </DxSummary>

          <DxFilterRow :visible="false"/>
          <DxSearchPanel :visible="false"/>
          <DxScrolling mode="standard"/>
          <DxGrouping :context-menu-enabled="false"/>
          <DxGroupPanel :visible="true"/>
        </DxDataGrid>
      </div>
    </div>

    <div v-if="dataLoadingPivot" class="spinner-border m-auto" role="status">
      <span class="sr-only">Loading...</span>
    </div>

    <div v-else style="width: 100%; height: 1700px">
      <fullscreen :fullscreen.sync="fullscreen1" background="fac78f">
        <div class="fullscreen-wrapper">
          <div class="long-title">
            <h3>{{ $t('table.personnel') }}</h3>
          </div>
          <DxChart ref="chart" @legend-click="onLegendClick">
            <DxExport :enabled="true"/>
            <DxTooltip
                :enabled="true"
                :customize-tooltip="customizeTooltipKapoRound"
            />
            <DxAdaptiveLayout :width="450"/>
            <DxSize :height="300"/>
            <DxCommonSeriesSettings type="stackedBar"/>
            <DxLegend
                vertical-alignment="bottom"
                horizontal-alignment="center"
            />

          </DxChart>
          <DxPivotGrid
              id="pivotgrid"
              ref="grid"
              :data-source="dataSource1"
              :allow-filtering="true"
              :show-borders="true"
              :show-column-grand-totals="false"
              :show-row-grand-totals="false"
              :show-row-totals="true"
              :show-column-totals="false"
              :column-auto-width="true"
              :data-field-area="true"
              :allow-expand-all="true"
              :row-header-layout="rowHeaderLayout"
              :word-wrap-enabled="false"
              @exporting="onExporting($event,$t('table.personnel'))"
          >
            <DxExport :enabled="true"/>
            <DxFieldChooser class="chooser" :enabled="true" :height="400"/>
          </DxPivotGrid>
        </div>
      </fullscreen>

      <DxButton
          :width="120"
          class="fullscren"
          text="Fullscreen"
          styling-mode="contained"
          @click="toggle1"
      />


      <div class="m-20"></div>

      <fullscreen :fullscreen.sync="fullscreen2" background="fac78f">
        <div class="fullscreen-wrapper">
          <div class="long-title">
            <h3>{{ $t('table.machine') }}</h3>
          </div>
          <DxChart ref="chart2" @legend-click="onLegendClick">
            <DxExport :enabled="true"/>
            <DxTooltip
                :enabled="true"
                :customize-tooltip="customizeTooltipKapo"
            />
            <DxAdaptiveLayout :width="450"/>
            <DxSize :height="400"/>
            <DxCommonSeriesSettings type="bar"/>
            <DxLegend
                vertical-alignment="bottom"
                horizontal-alignment="center"
                :column-count="5"
            />
          </DxChart>
          <DxPivotGrid
              id="pivotgrid2"
              ref="grid2"
              :data-source="dataSource2"
              :allow-filtering="true"
              :show-borders="true"
              :show-column-grand-totals="false"
              :show-row-grand-totals="false"
              :show-row-totals="false"
              :show-column-totals="false"
              :column-auto-width="true"
              :allow-expand-all="true"
              :row-header-layout="rowHeaderLayout"
              :word-wrap-enabled="false"
              @exporting="onExporting($event,$t('table.machine'))"
          >
            <DxExport :enabled="true"/>
            <DxFieldChooser :enabled="true" :height="400"/>
          </DxPivotGrid>
        </div>
      </fullscreen>
      <DxButton
          :width="120"
          class="fullscren"
          text="Fullscreen"
          styling-mode="contained"
          @click="toggle2"
      />
      <div class="m-20"></div>
      <fullscreen :fullscreen.sync="fullscreen3" background="fac78f">
        <div class="fullscreen-wrapper">
          <div class="long-title">
            <h3>{{ $t('table.efficiency') }}</h3>
          </div>
          <DxChart ref="chart3" @legend-click="onLegendClick"
          >
            <DxExport :enabled="true"/>
            <DxTooltip :enabled="true"
                       :customize-tooltip="customizeTooltipKapo"
            />
            <DxAdaptiveLayout :width="450"/>
            <DxSize :height="300"/>
            <DxCommonSeriesSettings type="bar"/>

            <DxLegend
                vertical-alignment="bottom"
                horizontal-alignment="center"
            />
          </DxChart>
          <DxPivotGrid
              id="pivotgrid3"
              ref="grid3"
              :data-source="dataSource3"
              :allow-filtering="true"
              :show-borders="true"
              :show-column-grand-totals="false"
              :show-row-grand-totals="false"
              :show-row-totals="false"
              :show-column-totals="false"
              :column-auto-width="true"
              :allow-expand-all="true"
              :row-header-layout="rowHeaderLayout"
              :word-wrap-enabled="false"
              @exporting="onExporting($event,$t('table.efficiency'))"
          >
            <DxExport :enabled="true"/>
            <DxFieldChooser :enabled="true" :height="400"/>
          </DxPivotGrid>
        </div>
      </fullscreen>
      <DxButton
          :width="120"
          class="fullscren"
          text="Fullscreen"
          styling-mode="contained"
          @click="toggle3"
      />
      <div class="m-20"></div>
      <fullscreen :fullscreen.sync="fullscreen4" background="fac78f">
        <div class="fullscreen-wrapper">
          <div class="long-title">
            <h3>{{ $t('table.problems') }}</h3>
          </div>
          <DxChart ref="chart4" @legend-click="onLegendClick">
            <DxExport :enabled="true"/>
            <DxTooltip :enabled="true"
                       :customize-tooltip="customizeTooltipKapo"
            />
            <DxAdaptiveLayout :width="450"/>
            <DxSize :height="300"/>
            <DxCommonSeriesSettings type="bar"/>
            <DxLegend
                vertical-alignment="bottom"
                horizontal-alignment="center"
            />
          </DxChart>

          <DxPivotGrid
              id="pivotgrid4"
              ref="grid4"
              :data-source="dataSource4"
              :allow-filtering="true"
              :show-borders="true"
              :show-column-grand-totals="false"
              :show-row-grand-totals="false"
              :show-row-totals="false"
              :show-column-totals="false"
              :column-auto-width="true"
              :allow-expand-all="true"
              :row-header-layout="rowHeaderLayout"
              :word-wrap-enabled="false"
              @exporting="onExporting($event,$t('table.problems'))"

          >
            <DxExport :enabled="true"/>
            <DxFieldChooser :enabled="true" :height="400"/>
          </DxPivotGrid>
        </div>
      </fullscreen>
      <DxButton
          :width="120"
          class="fullscren"
          text="Fullscreen"
          styling-mode="contained"
          @click="toggle4"
      />
      <div class="m-20"></div>

      <fullscreen :fullscreen.sync="fullscreen5" background="fac78f">
        <div class="fullscreen-wrapper">
          <div class="long-title">
            <h3>{{ $t('table.production') }}</h3>
          </div>
          <DxChart ref="chart5" @legend-click="onLegendClick">
            <DxExport :enabled="true"/>
            <DxTooltip :enabled="true"
                       :customize-tooltip="customizeTooltipKapo"
            />

            <DxAdaptiveLayout :width="450"/>
            <DxSize :height="300"/>
            <DxCommonSeriesSettings type="bar"/>
            <DxLegend
                vertical-alignment="bottom"
                horizontal-alignment="center"
            />
          </DxChart>
          <DxPivotGrid
              id="pivotgrid5"
              ref="grid5"
              :data-source="dataSource5"
              :allow-sorting-by-summary="true"
              :allow-filtering="true"
              :show-borders="true"
              :allow-expand-all="true"
              :show-column-grand-totals="false"
              :show-row-grand-totals="false"
              :show-row-totals="false"
              :show-column-totals="false"
              @exporting="onExporting($event,$t('table.production'))"
          >
            <DxExport :enabled="true"/>
            <DxFieldChooser :enabled="true" :height="400"/>
          </DxPivotGrid>
        </div>
      </fullscreen>
      <DxButton
          :width="120"
          class="fullscren"
          text="Fullscreen"
          styling-mode="contained"
          @click="toggle5"
      />
      <div class="m-20"></div>
    </div>
  </b-card>
</template>

<script>
import {Workbook} from 'exceljs';
import {exportPivotGrid} from 'devextreme/excel_exporter';
import saveAs from 'file-saver';
import axios from '@axios';
import {DxButton} from 'devextreme-vue/button';

import {BCard} from 'bootstrap-vue';

import 'devextreme-vue/text-area';
import {
  DxDataGrid,
  DxColumn,
  DxFilterRow,
  DxSearchPanel,
  DxLookup,
  DxScrolling,
  DxSummary,
  DxGroupItem,
  DxGroupPanel,
  DxGrouping,
} from 'devextreme-vue/data-grid';
import {
  DxChart,
  DxAdaptiveLayout,
  DxCommonSeriesSettings,
  DxSize,
  DxTooltip,
  DxLegend
} from 'devextreme-vue/chart';

import {DxPivotGrid, DxFieldChooser, DxExport} from 'devextreme-vue/pivot-grid';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import 'swiper/css/swiper.css';
import {Swiper, SwiperSlide} from 'vue-awesome-swiper';
import {BImg} from 'bootstrap-vue';
import moment from 'moment';
import fullscreen from 'vue-fullscreen'
import Vue from 'vue'

Vue.use(fullscreen)


Vue.use(fullscreen)
let activityListF = [];
import PivotGridDataSource from 'devextreme/ui/pivot_grid/data_source';

export default {
  components: {
    DxPivotGrid,
    DxExport,
    DxFieldChooser,
    DxChart,
    DxAdaptiveLayout,
    DxCommonSeriesSettings,
    DxSize,
    DxLegend,
    DxTooltip,
    Swiper,
    DxButton,
    SwiperSlide,
    BImg,
    BCard,
    DxDataGrid,
    DxColumn,
    DxFilterRow,
    DxLookup,
    DxSearchPanel,
    DxScrolling,
    DxSummary,
    DxGroupItem,
    DxGroupPanel,
    DxGrouping,
  },
  data() {
    return {
      fullscreen1: false,
      fullscreen2: false,
      fullscreen3: false,
      fullscreen4: false,
      fullscreen5: false,
      pivotData: [],
      selectedLanguage: {},
      filteredDate: null,
      data: [],
      dataUnit: [],
      dataPopUpWbsName: [],
      dataPopUpActivityName: [],
      dataPopUpUnit: [],
      dataPopUpCompany: [],
      dataImages: [],
      dataLoading: true,
      dataLoadingPivot: false,
      swiperLoading: true,
      can_list: false,
      error: '',
      showColumnGrandTotals: true,
      showRowGrandTotals: true,
      showRowTotals: true,
      showColumnTotals: true,
      rowHeaderLayout: '',
      dataSource1: {},
      dataSource2: {},
      dataSource3: {},
      dataSource4: {},
      dataSource5: {},
      workProblemVisible: true,
      setActivityCode(rowData, value) {
        this.defaultSetCellValue(rowData, value);
        rowData.activity_id = value;
      },
      setStateValue(rowData, value) {
        this.defaultSetCellValue(rowData, value);
        rowData.activity_id = null;
        rowData.wbs_id = value;
      },

      swiperData: this.dataImages,
      swiperDataMain: this.dataImages,
      dataForPivotPersonnel: [],
      dataForPivotMachine: [],
      dataForPivotPersonnelEff: [],
      dataForPivotProblem: [],
      swiperOptions: {
        slidesPerView: 5,
        spaceBetween: 10,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        breakpoints: {
          1024: {
            slidesPerView: 4,
            spaceBetween: 40
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 30
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 20
          },
          320: {
            slidesPerView: 1,
            spaceBetween: 10
          }
        }
      },
    };
  },

  async mounted() {
    await this.getPageAuths();
    await this.getLanguage();
    await this.getDatas();
    await this.getImages();
    await this.getPivotDataForDataSource1();
    await this.getPivotDataForDataSource2();
    await this.getPivotDataForDataSource3();
    await this.getPivotDataForDataSource4();
    this.refreshData();
  },
  methods: {
    onExporting(e,name) {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet(name);

      exportPivotGrid({
        component: e.component,
        worksheet,
      }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(new Blob([buffer], {type: 'application/octet-stream'}), name+'.xlsx');
        });
      });
      e.cancel = true;
    },
    onLegendClick({target: series}) {
      if (series.isVisible()) {
        series.hide();
      } else {
        series.show();
      }
    },
    customizeTooltipKapo(pointInfo) {
      return {
        text: `${pointInfo.seriesName}<br/>${(+pointInfo.valueText).toFixed(2)}`,
      };
    },
    customizeTooltipKapoRound(pointInfo) {
      return {
        text: `${pointInfo.seriesName}<br/>${(Math.round(+pointInfo.valueText)).toFixed(2)}`,
      };
    },
    customizeLabel({value}) {
      return {
        visible: true,
        customizeText({valueText}) {
          return +(value).toFixed(2);
        },
      }
      // if (value > this.highAverage) {
      //     return {
      //         visible: true,
      //         backgroundColor: '#ff7c7c',
      //         customizeText({ valueText }) {
      //             return `${valueText}&#176F22`;
      //         },
      //     };
      // }
      // return null;
    },
    customizeText({valueText}) {
      return `${valueText}&#176F`;
    },
    customizeTooltip1(args) {
      const valueText = (args.seriesName.indexOf('Total') !== -1)
          ? new Intl.NumberFormat('en-EN', {style: 'currency', currency: 'USD'}).format(args.originalValue)
          : args.originalValue;

      return {
        html: `${args.seriesName}<div class='currency'>${
            valueText}</div>`,
      };
    },
    customizeTooltip2(args) {
      const valueText = (args.seriesName.indexOf('Total') !== -1)
          ? new Intl.NumberFormat('en-EN', {style: 'currency', currency: 'USD'}).format(args.originalValue)
          : args.originalValue;

      return {
        html: `${args.seriesName}<div class='currency'>${
            valueText}</div>`,
      };
    },
    customizeTooltip3(args) {
      const valueText = (args.seriesName.indexOf('Total') !== -1)
          ? new Intl.NumberFormat('en-EN', {style: 'currency', currency: 'USD'}).format(args.originalValue)
          : args.originalValue;

      return {
        html: `${args.seriesName}<div class='currency'>${
            valueText}</div>`,
      };
    },

    customizeTooltip5(args) {
      const valueText = (args.seriesName.indexOf('Total') !== -1)
          ? new Intl.NumberFormat('en-EN', {style: 'currency', currency: 'USD'}).format(args.originalValue)
          : args.originalValue;

      return {
        html: `${args.seriesName}<div class='currency'>${
            valueText}</div>`,
      };
    },


    toggle1() {
      this.fullscreen1 = !this.fullscreen1;
    },
    toggle2() {
      this.fullscreen2 = !this.fullscreen2;
    },
    toggle3() {
      this.fullscreen3 = !this.fullscreen3;
    },
    toggle4() {
      this.fullscreen4 = !this.fullscreen4;
    },
    toggle5() {
      this.fullscreen5 = !this.fullscreen5;
    },
    async getPivotDataForDataSource1(direction) {
      const response =
          (await axios.get('/pivot-report-home-personnel'));
      try {
        this.dataForPivotPersonnel = response?.data?.result || [];


        this.dataSource1 = new PivotGridDataSource({
          fields: [
            {
              caption: 'Per Gr Name',
              dataField: 'group',
              area: 'row'
            },
            {
              dataField: 'work_date',
              dataType: 'date',
              area: 'column'
            },
            {
              groupName: 'work_date',
              groupInterval: 'week',
              selector: function (data) {
                let currentDate = new Date(data.work_date);
                let startDate = new Date(currentDate.getFullYear(), 0, 1);
                var days = Math.floor((currentDate - startDate) /
                    (24 * 60 * 60 * 1000));

                var weekNumber = Math.ceil(days / 7);
                return weekNumber;
              },
              visible: true
            },
            {
              groupName: 'work_date',
              groupInterval: 'day',
              visible: true
            },
            {
              // ...
              caption: 'Pers Avg',
              dataField: 'count',
              dataType: 'number',
              area: 'data',
              format: {type: 'fixedPoint', precision: 0},
              summaryType: 'avg',
              calculateSummaryValue: function (summaryCell) {
                let sum = 0
                let sumColumn = 0
                summaryCell.children('row').forEach(s => {
                  sum += s.value()
                })
                summaryCell.children('column').forEach(s => {
                  sumColumn += s.value()
                })
                return sum > 0 ? sum : summaryCell.value()
                // Your code goes here
              }
            },

          ],
          store: this.dataForPivotPersonnel

        });
        const pivotGrid = this.$refs.grid.instance;
        const chart = this.$refs.chart.instance;
        pivotGrid.bindChart(chart, {
          dataFieldsDisplayMode: 'splitPanes',
          alternateDataFields: false
        });
      } catch (error) {
        this.dataForPivotPersonnel = [];
        // this.error = e?.response?.data?.message || e?.message;
      }
    },
    async getPivotDataForDataSource2() {
      const response =
          (await axios.get('/pivot-report-home-machine'));
      try {
        this.dataForPivotMachine = response?.data?.result || [];
        this.dataForPivotMachine.forEach(d => {
          d.type = this.$t(d.type)
        })
        this.dataSource2 = new PivotGridDataSource({
          fields: [
            {
              sortOrder: 'asc',
              area: 'row',
              sortBy: 'value',
              caption: 'Machine Code',
              selector: function (data) {
                let d = data.code.split('.')
                let order1 = d[0]
                let order2 = d[1]
                if (order2.length == 2) order2 = "0" + order2
                return order1 + '.' + order2
              }

            },
            {
              caption: 'Machine Name',
              dataField: 'group',
              area: 'row'
            },
            {
              dataField: 'work_date',
              dataType: 'date',
              area: 'column'
            },
            {
              groupName: 'work_date',
              groupInterval: 'week',
              selector: function (data) {
                let currentDate = new Date(data.work_date);
                let startDate = new Date(currentDate.getFullYear(), 0, 1);
                var days = Math.floor((currentDate - startDate) /
                    (24 * 60 * 60 * 1000));

                var weekNumber = Math.ceil(days / 7);
                return weekNumber;
              },
              visible: true
            },
            {
              groupName: 'work_date',
              groupInterval: 'day',
              visible: true
            },
            {
              caption: 'Mach Avg',
              dataField: 'count',
              dataType: 'number',
              summaryType: 'avg',
              area: 'data',
              format: {type: 'fixedPoint', precision: 0}
            },
          ],
          store: this.dataForPivotMachine
        });
        const pivotGrid = this.$refs.grid2.instance;
        const chart = this.$refs.chart2.instance;
        pivotGrid.bindChart(chart, {
          dataFieldsDisplayMode: 'splitPanes',
          alternateDataFields: false
        });
      } catch (error) {
        this.dataForPivotMachine = [];
        // this.error = e?.response?.data?.message || e?.message;
      }
    },
    async getPivotDataForDataSource4() {
      const response =
          (await axios
              .get(
                  '/pivot-report-home-problem'));
      try {
        this.dataForPivotProblem = response?.data?.result || [];
        this.dataForPivotProblem.forEach(d => {
          d.type = this.$t(d.type)
        })
        this.dataSource4 = new PivotGridDataSource({
          fields: [
            {
              caption: 'Type',
              dataField: 'type',
              area: 'row'
            },
            {
              caption: 'Resolved?',
              dataField: 'is_resolved',
              area: 'column'
            },
            {
              caption: ' Problem',
              dataField: 'problemLong',
              area: 'row'
            },
            {
              caption: 'General  Problem',
              dataField: 'problemLong',
              area: 'row'
            },
            {
              dataField: 'created_at',
              dataType: 'date',
              area: 'column'
            },
            {
              groupName: 'created_at',
              groupInterval: 'week',
              selector: function (data) {
                let currentDate = new Date(data.work_date);
                let startDate = new Date(currentDate.getFullYear(), 0, 1);
                var days = Math.floor((currentDate - startDate) /
                    (24 * 60 * 60 * 1000));

                var weekNumber = Math.ceil(days / 7);
                return weekNumber;
              },
              visible: true
            },
            {
              groupName: 'created_at',
              groupInterval: 'day',
              visible: true
            },
            {
              caption: 'Count',
              dataField: 'problemCode',
              dataType: 'number',
              summaryType: 'count',
              area: 'data',
            },
          ],
          store: this.dataForPivotProblem
        });
        const pivotGrid = this.$refs.grid4.instance;
        const chart = this.$refs.chart4.instance;
        pivotGrid.bindChart(chart, {
          dataFieldsDisplayMode: 'splitPanes',
          alternateDataFields: false
        });
        //utkukapo
      } catch (error) {
        this.dataForPivotProblem = []
        // this.error = e?.response?.data?.message || e?.message;
      }
    },
    async getPivotDataForDataSource3() {
      const response =
          (await axios.get('/pivot-report-home-personnel-efficiency'));
      try {

        this.dataForPivotPersonnelEff = response?.data || [];
        this.dataForPivotPersonnelEff.forEach(d => {
          d.type = this.$t(d.type)
        })

        this.dataSource3 = new PivotGridDataSource({
          fields: [
            {dataField: 'nnetQty', visible: false},
            {dataField: 'persNo', visible: false},
            {dataField: 'unitName', visible: false},
            {
              caption: 'Activity Ro1 Desc',
              dataField: 'report_order_1',
              area: 'row'
            },
            {
              caption: 'Act Name',
              dataField: 'activityName',
              width: 270,
            },
            {
              caption: 'Act RO1 Qty',
              dataField: 'ro1Qty',
              summaryType: 'sum',
              format: function (value) {
                return value.toFixed(0);
              }
            },

            {
              caption: 'Act UM',
              dataField: 'activityUM'
            },
            {
              caption: 'Pers Inc HR2',
              dataField: 'persIncHR2',
              summaryType: 'sum',
              format: {type: 'fixedPoint', precision: 3}
            },
            {
              caption: 'Pers Inc No2',
              dataField: 'persIncNo2',
              area: 'data',
              summaryType: 'sum',
              format: {type: 'fixedPoint', precision: 2}
            },
            {
              caption: 'Activity Ro1 UM',
              dataField: 'unitSymbol',
              area: 'row'
            },
            {
              dataField: 'work_date',
              dataType: 'date',
              area: 'column'
            },
            {
              groupName: 'work_date',
              groupInterval: 'week',
              selector: function (data) {
                let currentDate = new Date(data.work_date);
                let startDate = new Date(currentDate.getFullYear(), 0, 1);
                var days = Math.floor((currentDate - startDate) /
                    (24 * 60 * 60 * 1000));

                var weekNumber = Math.ceil(days / 7);
                return weekNumber;
              },
              visible: true
            },
            {
              groupName: 'work_date',
              groupInterval: 'day',
              visible: true
            }
          ],
          store: this.dataForPivotPersonnelEff
        });
        const pivotGrid = this.$refs.grid3.instance;
        const chart = this.$refs.chart3.instance;
        pivotGrid.bindChart(chart, {
          dataFieldsDisplayMode: 'splitPanes',
          alternateDataFields: false
        });

        this.dataSource5 = new PivotGridDataSource({
          fields: [
            {
              dataField: 'nnetQty',
              visible: false
            }, {
              dataField: 'persIncNo',
              visible: false
            }, {
              dataField: 'persIncNo2',
              visible: false
            }, {
              dataField: 'persNo',
              visible: false
            }, {
              dataField: 'unitName',
              visible: false
            },
            {
              caption: 'Activity R.O.1 Descr',
              dataField: 'report_order_1',
              area: 'row'
            },
            {
              caption: 'Activity RO1 UM',
              dataField: 'unitSymbol',
              area: 'row'
            },
            {
              dataField: 'work_date',
              dataType: 'date',
              area: 'column'
            },
            {
              caption: this.$t('firm.name'),
              dataField: 'firmName',


            },
            {
              caption: this.$t('firm.code'),
              dataField: 'firmCode',


            },
            {
              groupName: 'work_date',
              groupInterval: 'week',
              selector: function (data) {
                let currentDate = new Date(data.work_date);
                let startDate = new Date(currentDate.getFullYear(), 0, 1);
                var days = Math.floor((currentDate - startDate) /
                    (24 * 60 * 60 * 1000));

                var weekNumber = Math.ceil(days / 7);
                return weekNumber;
              },
              visible: true
            },
            {
              groupName: 'work_date',
              groupInterval: 'day',
              visible: true
            },
            {

              dataType: 'number',
              format: {type: 'fixedPoint', precision: 2},
              caption: 'Act RO1 Qty',
              dataField: 'rro1Qty',
              summaryType: 'sum',
              area: 'data'
            },
            {
              dataField: 'nnetQty',
              visible: false
            }
          ],
          store: this.dataForPivotPersonnelEff
        });
        const pivotGrid5 = this.$refs.grid5.instance;
        const chart5 = this.$refs.chart5.instance;
        pivotGrid5.bindChart(chart5, {
          dataFieldsDisplayMode: 'splitPanes',
          alternateDataFields: false
        });

      } catch (error) {
        this.dataForPivotPersonnelEff = [];
        // this.error = e?.response?.data?.message || e?.message;
      }
    },
    filterDateChange(e) {
      let filterDate =
          e.value !== null ? moment(String(e.value)).format('YYYY-MM-DD') : null;
      this.filteredDate = filterDate;
      this.swiperData = this.swiperDataMain.filter(
          (p) =>
              p.work.work_date === this.filteredDate || this.filteredDate === null
      );
    },
    async getPageAuths() {
      var q = localStorage.getItem('userInfo')
      const pageAuths = await axios.post('/get-page-auth', {page: 'home'});
      this.can_list = pageAuths.data.can_list;
    },
    orderDateHeaderFilter(data) {
      data.dataSource.postProcess = (results) => {
        results.push({
          text: 'Weekends',
          value: 'weekends'
        });
        return results;
      };
    },
    async getLanguage() {
      this.error = '';
      const response = await axios.get('/list-language');

      try {
        this.languages = response?.data?.result || [];
        const selected = this.languages.find(
            (x) => x?.code === localStorage.dbLanguage
        );
        this.selectedLanguage = selected;
      } catch (error) {
        this.error = e?.response?.data?.message || e?.message;
      }
    },
    async getUnit() {
      this.error = '';
      const response = await axios.get('/list-unit');

      try {
        this.dataUnit = response?.data?.result || [];
      } catch (error) {
        this.error = e?.response?.data?.message || e?.message;
      }
    },
    blobToBase64(blob) {

      let q = btoa(
          blob.reduce((data, byte) => data + String.fromCharCode(byte), '')
      )
      return q
    },
    onLanguageSelect(lang) {
      this.selectedLanguage = lang;
      localStorage.dbLanguage = lang.code;
      this.getDatas();
    },

    getFilteredActivity: (options) => ({
      store: activityListF,
      filter: options.data ? ['wbs_id', '=', options.data.wbs_id] : null
    }),
    async getActivityList() {
      this.error = '';
      const response = await axios.get('/list-activity-dash');

      try {
        this.dataPopUpActivityName = response?.data?.result || [];
        activityListF = response?.data?.result || [];
      } catch (error) {
        this.error = e?.response?.data?.message || e?.message;
      }
    },
    async getWbsName() {
      this.error = '';
      const response = await axios.get('/list-wbs?noHi=true');

      try {
        this.dataPopUpWbsName = response?.data?.result || [];
      } catch (error) {
        this.error = e?.response?.data?.message || e?.message;
      }
    },
    async getCompanyName() {
      this.error = '';
      const response = await axios.get('/list-sub-firm');

      try {
        this.dataPopUpCompany = response?.data?.result || [];
        this.dataPopUpCompany.forEach(d => {
          if (d.isMainFirm) {
            d.real_firm_id = 'm' + d.id
          } else {
            d.real_firm_id = 't' + d.id
          }
        })
      } catch (error) {
        this.error = e?.response?.data?.message || e?.message;
      }
    },
    async getImages() {
      this.swiperLoading = false;
      this.error = '';
      const response = await axios.get('/list-work-images-for-home');

      try {
        this.dataImages = response?.data?.result || [];
        this.swiperData = this.dataImages
        this.swiperDataMain = this.dataImages
        this.swiperLoading = false
      } catch (error) {
        this.error = error?.response?.data?.message || error?.message;
        this.swiperLoading = false;
      }
    },
    async getUnitName() {
      this.error = '';
      const response = await axios.get('/list-unit');

      try {
        this.dataPopUpUnit = response?.data?.result || [];
      } catch (error) {
        this.error = e?.response?.data?.message || e?.message;
      }
    },
    async getDatas() {
      await this.getActivityList();
      await this.getUnit();
      await this.getWbsName();
      await this.getUnitName();
      await this.getCompanyName();
    },
    refreshData() {
      this.dataLoading = true;
      this.error = '';
      axios
          .get('/list-work')
          .then((response) => {
            if (response.status === 200) {
              this.data = response?.data?.result || [];
              this.data.forEach(d => {
                if (d.is_main_firm) {
                  d.real_firm_id = 'm' + d.firm_id
                } else {
                  d.real_firm_id = 't' + d.sub_firm_id
                }
                d.companyName = ""
                let nameCheck = this.dataPopUpCompany.find(o => o.real_firm_id == d.real_firm_id)
                if (nameCheck) d.companyName = nameCheck.name
                d.work_img_1 = null
                d.work_img_2 = null
                d.work_img_3 = null
                let imgs = this.dataImages.filter(a => a.work_id == d.id)
                if (imgs.length >= 1) d.work_img_1 = imgs[0]
                if (imgs.length >= 2) d.work_img_2 = imgs[1]
                if (imgs.length >= 3) d.work_img_3 = imgs[2]
              })
              this.dataLoading = false;
            } else {
              this.error = response?.data?.message || 'Unexpected API error';
              this.dataLoading = false;
            }
          })
          .catch((e) => {
            this.error = e?.response?.data?.message || e?.message;
            this.dataLoading = false;
          });
    },
    showToast({variant = 'success', title, text, icon = 'XIcon'}) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          text,
          icon,
          variant
        }
      });
    }
  }
};
</script>

<style lang="scss">
.fama-table img:not(.famayellow) {
  height: 100px;
  display: block;
}

@import './tables/tables.scss';

.bg-card {
  background: #ffffff;
  /*height: 2050px;*/
  height: 5500px;
}

//.bg-card {
//
//  height: 7000px;
//}

.dx-master-detail-cell {
  background-color: #ffc107 !important;
}

.dx-datagrid-rowsview .dx-row-focused.dx-data-row .dx-command-edit .dx-link,
.dx-datagrid-rowsview .dx-row-focused.dx-data-row > td:not(.dx-focused),
.dx-datagrid-rowsview .dx-row-focused.dx-data-row > tr > td:not(.dx-focused) {
  background-color: #ffc107;
  color: #fff;
  border-bottom: none;
}

.dx-state-readonly {
  background: #ededed !important;
}

.table_grid_top {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.dx-datagrid .dx-link {
  color: #009fb1 !important;
}

.table_grid_top #exportButton {
  margin-right: 10px;
}

.fama-table > input {
  width: auto;
}

.m-20 {
  margin: 50px 0px;
  border: 1px solid #eee;
}

.long-title {
  text-align: center;
}

.fullscren {
  margin-left: 50px;
}

.fullscreen-wrapper {
  height: 100%;
  background: #ffffff;
  align-items: center;
  justify-content: center;
  padding: 50px;
}

.chooser {
  background-color: #1ab7ea;
}

//.fullscreen-wrapper {
//
//  background: #ffffff;
//  display: flex;
//  flex-direction: column;
//  align-items: center;
//  justify-content: center;
//  padding: 20px;
//
//  .button {
//    margin-bottom: 20px;
//  }
//}
</style>
